'use client';

import React, { useState } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';

type Props = {
  trigger: React.ReactNode;
  delay?: number;
  open?: boolean;
  onOpenChange?: (force: boolean) => void;
};

const Tooltip = ({ children, trigger, open, onOpenChange, delay = 0 }: React.PropsWithChildren<Props>) => {
  const [prevIsOpen, setPrevIsOpen] = useState(Boolean(open));
  const [isOpen, setOpen] = useState(Boolean(open));

  if (open !== undefined && prevIsOpen !== open) {
    setPrevIsOpen(open);
    setOpen(open);
  }

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root
        delayDuration={delay}
        open={isOpen}
        onOpenChange={(next) => {
          onOpenChange?.(next);

          if (open === undefined) {
            setOpen(next);
          }
        }}
      >
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className='data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-sm will-change-[transform,opacity]'
            sideOffset={5}
          >
            {children}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
