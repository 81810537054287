'use client';

import { memo, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import clx from '../../utils/clx';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { useWorldMap } from './WorldMap';
import './style.css';

export const useMakeMarker = (coordinates: [number, number]) => {
  const { map, createMarker } = useWorldMap();

  const marker = useMemo(() => {
    const marker = createMarker({
      anchor: 'bottom',
      element: document.createElement('div'),
    });
    marker.setLngLat(coordinates);

    return marker;
  }, [coordinates, createMarker]);

  useEffect(() => {
    marker.addTo(map);

    return () => {
      marker.remove();
    };
  }, [map, marker]);

  return marker;
};

export type Props = React.HTMLProps<HTMLButtonElement> & {
  coordinates: [number, number];
  iconClassName?: string;
  tooltipProps?: Partial<React.ComponentProps<typeof Tooltip>>;
};

const Marker = memo<Props>(
  ({ children, coordinates, iconClassName, tooltipProps, ...props }: Props) => {
    const marker = useMakeMarker(coordinates);

    return (
      <>
        {createPortal(
          children ? (
            <Tooltip
              delay={0}
              {...tooltipProps}
              trigger={
                <button {...props} type='button'>
                  <Icon name='marker' className={clx('top-0 h-full w-full', iconClassName)} stroke='none' />
                </button>
              }
            >
              {children}
            </Tooltip>
          ) : (
            <Icon name='marker' namespace='common' className='top-0 h-10 w-8' fill='none' stroke='none' />
          ),
          marker.getElement()
        )}
      </>
    );
  },
  (prev: Props, next: Props) => prev?.tooltipProps?.open === next.tooltipProps?.open
);

Marker.displayName = 'Marker';

export default Marker;
