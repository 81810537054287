import { useSearchParams } from 'next/navigation';

import useSWR from 'swr';

const usePropertiesCluster = () => {
  const searchQueryString = useSearchParams()?.toString();
  return useSWR('/api/properties/geo' + (searchQueryString ? `?${searchQueryString}` : ''), (query: string) =>
    fetch(query).then((res) => res.json())
  );
};

export default usePropertiesCluster;
