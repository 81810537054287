'use client';

import { memo } from 'react';
import { createPortal } from 'react-dom';

import clx from '../../utils/clx';
import Icon from '../Icon';
import { useMakeMarker } from './Marker';

type Props = React.HTMLProps<HTMLButtonElement> & {
  count: number;
  coordinates: [number, number];
};

const Cluster = memo(
  ({ count, coordinates, className, onClick }: Props) => {
    const marker = useMakeMarker(coordinates);
    return (
      <>
        {createPortal(
          <button
            className={clx(
              'relative grid h-6 w-6 place-items-center before:absolute before:h-[52px] before:w-[52px] before:rounded-full before:bg-white/20',
              className
            )}
            onClick={onClick}
          >
            <Icon name='cluster' className='-top-px h-6 w-6' fill='white' />
            <div className='text-1-black absolute inset-0 grid h-full w-full place-items-center text-[17px] font-medium'>
              {count}
            </div>
          </button>,
          marker.getElement()
        )}
      </>
    );
  },
  (prev: Props, next: Props) =>
    prev?.coordinates[0] === next.coordinates[0] && prev?.coordinates[1] === next.coordinates[1]
);

Cluster.displayName = 'Cluster';

export default Cluster;
