'use client';

import { createContext, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
// @ts-ignore
import { use } from 'react';

// biome-ignore lint/suspicious/noShadowRestrictedNames: mapbox-gl export Map
import type { LngLatLike, Map, Marker } from 'mapbox-gl';
import { useMinSize } from '../../hooks/useWindowSize';
import 'mapbox-gl/dist/mapbox-gl.css';

type MarkerOptions = {
  element?: HTMLElement;
  color?: string;
  scale?: number;
  anchor: 'bottom';
  draggable?: boolean;
  clickTolerance?: number;
  rotation?: number;
  rotationAlignment?: string;
  pitchAlignment?: string;
  occludedOpacity?: number;
  className?: string;
};

if (!process.env.NEXT_PUBLIC_MAPBOX_TOKEN) {
  throw new Error('You need to provide a Mapbox Token.');
}

const mapbox = (() => {
  const load = async () => {
    const { default: mapboxgl } = await import('mapbox-gl');
    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN || '';
    return mapboxgl;
  };

  let isCalled = false;
  let pendingMapbox: Promise<typeof import('mapbox-gl')['default']> | undefined;

  return () => {
    if (isCalled) {
      return pendingMapbox;
    }

    pendingMapbox = load();
    isCalled = true;
    return pendingMapbox;
  };
})();

type MapContext = {
  map: Map;
  createMarker: (options: MarkerOptions) => Marker;
};

const WorldMapContext = createContext<MapContext | undefined>(undefined);

const LONGITUDE = -99.53;
const LATITUDE = 39;

const DEFAULT_ZOOM = 3.4;

let inMemoryState = {
  zoom: DEFAULT_ZOOM,
  center: [LONGITUDE, LATITUDE] as LngLatLike,
};

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'ref'> & {
  zoom?: number;
  mobileZoom?: number;
  center?: LngLatLike;
  interactive?: boolean;
  innerRef?: React.Ref<{ map?: Map | undefined; container: HTMLDivElement | null }>;
};

const WorldMap = ({
  children,
  zoom = inMemoryState.zoom,
  mobileZoom = 2.2,
  center = inMemoryState.center,
  interactive = true,
  innerRef,
  ...props
}: Props) => {
  const [map, setState] = useState<Map | undefined>(undefined);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const isMin = useMinSize();
  const mapboxgl = use(mapbox());

  useImperativeHandle(innerRef, () => ({
    map,
    container: mapContainerRef.current,
  }));

  useEffect(() => {
    if (!mapboxgl || !mapContainerRef.current) {
      return;
    }

    setState(
      (map) =>
        map ||
        new mapboxgl.Map({
          container: mapContainerRef.current || '',
          style: 'mapbox://styles/nickkrekow/cl5sqsncw000414npn5zjh30w',
          center,
          zoom,
          attributionControl: false,
          bearing: 10,
          interactive,
        })
    );
  }, [zoom, center, interactive, mapboxgl]);

  useEffect(() => {
    if (!map || !isMin) {
      return;
    }

    map.setZoom(isMin.xl() ? zoom : mobileZoom);

    if (interactive) {
      const handleMapState = () => {
        inMemoryState = {
          zoom: map.getZoom(),
          center: map.getCenter().toArray() as LngLatLike,
        };
      };
      map.on('render', handleMapState);
      return () => {
        map.off('render', handleMapState);
      };
    }
  }, [map, zoom, mobileZoom, interactive, isMin]);

  const value = useMemo(
    () =>
      map
        ? {
            map: map,
            createMarker: (options: MarkerOptions) => new mapboxgl.Marker(options),
          }
        : undefined,
    [mapboxgl, map]
  );

  return (
    <WorldMapContext.Provider value={value}>
      <div ref={mapContainerRef} {...props}>
        <>{map ? children : undefined}</>
      </div>
      <noscript>
        <div className='absolute inset-0 -z-10 bg-cover bg-no-repeat' />
      </noscript>
    </WorldMapContext.Provider>
  );
};

export default WorldMap;

export const useWorldMap = () => {
  const context = useContext(WorldMapContext);

  if (!context) {
    throw new Error(
      'You are trying to use the WorldMap Element outside of the WorldMap or World Map is not initialized.'
    );
  }

  return context;
};
