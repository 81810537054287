'use client';

import { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/navigation';

import DialogPortalContainerProvider from '@wander/ui/src/components/Dialog/DialogPortalContainerProvider';
import Cluster from '@wander/ui/src/components/WorldMap/Cluster';
import Marker from '@wander/ui/src/components/WorldMap/Marker';
import { useWorldMap } from '@wander/ui/src/components/WorldMap/WorldMap';

import type { PropertyLandingPage } from '@/features/properties/actions/toLandingProperty';
import Property from '@/features/properties/components/Property';
import type { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';
import usePropertiesCluster from './hook/usePropertiesCluster';

const toProperty = (property: any): PropertyLandingPage => {
  if (property?.medias && typeof property.medias === 'string') {
    property.medias = JSON.parse(property.medias);
  }

  if (property?.address && typeof property.address === 'string') {
    property.address = JSON.parse(property.address);
  }

  if (property?.nextAvailable && typeof property.nextAvailable === 'string') {
    property.nextAvailable = JSON.parse(property.nextAvailable);
  }

  return property;
};

const useUpdateClusterOnSearch = (map?: mapboxgl.Map) => {
  const { data } = usePropertiesCluster();

  useEffect(() => {
    if (data && map && map.getSource('properties')) {
      // @ts-ignore
      map.getSource('properties').setData(data);
    }
  }, [data, map]);

  return data;
};

type ClusterState = mapboxgl.MapboxGeoJSONFeature[];

const useCluster = (clusterRadius = 15) => {
  const { map } = useWorldMap();
  const isMounted = useRef(false);
  const [cluster, setCluster] = useState<ClusterState>([]);
  const defaultData = useUpdateClusterOnSearch(map);
  useEffect(() => {
    if (!map || isMounted.current) return;

    isMounted.current = true;

    const handleOnRenderMap = () => {
      if (map.isSourceLoaded('properties')) {
        setCluster(map.querySourceFeatures('properties') as ClusterState);
      }
    };

    map.once('load', () => {
      map.addSource('properties', {
        type: 'geojson',
        data: defaultData ? defaultData : '/api/properties/geo' + window.location.search,
        cluster: true,
        clusterRadius,
      });

      map.addLayer({
        id: 'cluster',
        type: 'symbol',
        source: 'properties',
      });

      map.on('render', handleOnRenderMap);
    });

    return () => {
      map.off('render', handleOnRenderMap);
    };
  }, [map]);

  const zoomHandler = (clusterId: number, coordinates: [number, number]) => () => {
    (map.getSource('properties') as GeoJSONSource).getClusterExpansionZoom(
      clusterId,
      (err?: unknown, zoom?: number | null) => {
        if (err) return;
        map.easeTo({
          center: coordinates,
          zoom: zoom || undefined,
        });
      }
    );
  };

  return [cluster, zoomHandler] as const;
};

const isCurrentMarker = (event: MouseEvent, openProperty: string) =>
  event
    .composedPath()
    .find((el: EventTarget) => (el instanceof HTMLElement ? el.getAttribute('data-property') == openProperty : false));

const useOpenProperty = () => {
  const [openProperty, setOpenProperty] = useState(undefined);
  const { map } = useWorldMap();

  useEffect(() => {
    const handleOnClickMap = (e: MapMouseEvent) => {
      setOpenProperty((openProperty) => {
        if (!openProperty || !isCurrentMarker(e.originalEvent, openProperty)) {
          return undefined;
        }
        return openProperty;
      });
    };

    const handleReset = () => setOpenProperty(undefined);

    map.on('click', handleOnClickMap);
    map.on('move', handleReset);
    map.on('zoom', handleReset);
    return () => {
      map.off('click', handleOnClickMap);
      map.off('move', handleReset);
      map.off('zoom', handleReset);
    };
  }, [map]);

  return [openProperty, setOpenProperty] as const;
};

type Props = {
  clusterRadius?: number;
  noDetails?: boolean;
};

const MapPropertiesCluster = ({ clusterRadius = 15, noDetails = false }: Props) => {
  const [cluster, zoomHandler] = useCluster(clusterRadius);
  const router = useRouter();
  const [openProperty, setOpenProperty] = useOpenProperty();

  // deduplicate some point that appears on different tile 🤷
  const _set = new Set();

  return cluster.map((feature) => {
    // @ts-ignore
    const coordinates = feature.geometry.coordinates as [number, number];
    const properties = feature.properties;

    if (!properties) return null;

    if (properties.cluster) {
      if (_set.has(properties.cluster_id)) return null;
      _set.add(properties.cluster_id);

      return (
        <Cluster
          key={properties.cluster_id}
          count={properties.point_count}
          coordinates={coordinates}
          onClick={zoomHandler(properties.cluster_id, coordinates)}
        />
      );
    }

    if (_set.has(properties.slug)) return null;

    const property = toProperty(properties);

    _set.add(property.slug);

    return noDetails ? (
      <Marker
        key={properties.slug}
        coordinates={coordinates}
        className='relative flex h-[35px] w-[26px] origin-bottom items-end text-white'
      />
    ) : (
      <Marker
        key={properties.slug}
        coordinates={coordinates}
        onClick={() => setOpenProperty(properties.slug)}
        onPointerEnter={() => router.prefetch(`property/${properties.slug}`)}
        className='relative flex h-[35px] w-[26px] origin-bottom items-end 
        text-white
        transition-transform
        duration-200
        @container/marker
        [&[data-selected="true"]]:translate-y-[-2px]
        [&[data-selected="true"]]:scale-110
        [&[data-state="delayed-open"]]:translate-y-[-2px]
        [&[data-state="delayed-open"]]:scale-110
        '
        tooltipProps={{
          open: openProperty === properties.slug,
          onOpenChange: (open) => setOpenProperty(open ? properties.slug : undefined),
        }}
        data-selected={openProperty === properties.slug}
        data-property={properties.slug}
      >
        <div
          id={properties.slug + '_map_marker'}
          className='group/marker is relative z-50 w-min rounded-lg bg-1-black before:absolute before:left-1/2 before:block before:h-[115%] before:w-[26px] before:-translate-x-1/2'
        >
          <DialogPortalContainerProvider portalContainerId={properties.slug + '_map_marker'} className='relative'>
            <Property property={property} lazyLoad={true} />
          </DialogPortalContainerProvider>
        </div>
      </Marker>
    );
  });
};

export default MapPropertiesCluster;
