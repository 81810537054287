'use client';

import MapPropertiesCluster from '@/features/mapCluster/MapPropertiesCluster';
import WorldMap from '@wander/ui/src/components/WorldMap';
import { useMinSize } from '@wander/ui/src/hooks/useWindowSize';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef } from 'react';

const ZOOM = 3.5;
const MOBILE_ZOOM = 2.2;
const LONGITUDE = -108.80743043432437;
const LATITUDE = -3.501330047013937;

const Globe = () => {
  const isMin = useMinSize();
  const { scrollYProgress } = useScroll();
  const mapRef = useRef<{
    map?: mapboxgl.Map;
    container: HTMLDivElement | null;
  }>(null);

  useMotionValueEvent(scrollYProgress, 'change', (v) => {
    if (!isMin) {
      return;
    }

    const defaultZoom = isMin.xl() ? ZOOM : MOBILE_ZOOM;
    if (mapRef.current?.map && mapRef.current?.container) {
      const { map, container } = mapRef.current;
      map.setZoom(defaultZoom - v / 5);
      map.setCenter([LONGITUDE, Math.max(-90, Math.min(90, LATITUDE - v * 50))]);
      const opacity = 1 - v;
      opacity > 0 ? (container.style.opacity = String(opacity)) : (container.style.opacity = '0');
    }
  });

  if (!isMin) {
    return null;
  }

  return (
    <div className='fixed top-0 z-[-1] overflow-hidden h-screen w-screen'>
      <WorldMap
        innerRef={mapRef}
        className='relative mx-auto w-full h-[120svh] xl:h-[260svh] max-h-[2000px] top-[40svh] left-1/2 -translate-x-1/2'
        zoom={isMin.xl() ? ZOOM : MOBILE_ZOOM}
        mobileZoom={MOBILE_ZOOM}
        center={[LONGITUDE, LATITUDE]}
        interactive={false}
      >
        <MapPropertiesCluster clusterRadius={0} />
      </WorldMap>
    </div>
  );
};

export default Globe;
